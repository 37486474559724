import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { BiRightArrowCircle } from "react-icons/bi";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import { AiFillStar, AiFillYoutube } from "react-icons/ai";
import Slider from "react-slick";
import instance from "../../api/web_instance";
import { message } from "antd";
import { useState } from "react";
import { Helmet } from "react-helmet";
import ShimmerEffect from "../../component/ShimmerEffect";
import HomeProductList from "./HomeProductList";

export default function HomePage() {
  const [commonData, setCommonData] = useState({});
  const [product, setProduct] = useState([]);
  const [services, setServices] = useState({});
  const [testimonials, setTestimonials] = useState({});
  const [seoData, setSeoData] = useState({});
  const [featuredVideos, setFeaturedVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const get_data = () => {
    setIsLoading(true);
    var config = {
      url: `/home-data`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setCommonData(response.data.data.common);
            setProduct(response.data.data.product);
            setServices(response.data.data.services);
            setTestimonials(response.data.data.testimonials);
            setSeoData(response?.data?.data?.seo_data);
            setFeaturedVideos(response?.data?.data?.featured_videos ?? []);
            setIsLoading(false);
          } else {
            message.error(response.data.message);
            setIsLoading(false);
          }
        } else {
          message.error(response.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        message.error(error.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    get_data();
  }, []);
  var header_settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // cssEase: "linear"
  };
  var settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var videoSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: featuredVideos?.length > 3 ? 3 : featuredVideos?.length,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: featuredVideos?.length > 3 ? 3 : featuredVideos?.length,
          slidesToScroll:
            featuredVideos?.length > 3 ? 3 : featuredVideos?.length,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: featuredVideos?.length > 2 ? 2 : featuredVideos?.length,
          slidesToScroll:
            featuredVideos?.length > 2 ? 2 : featuredVideos?.length,
          initialSlide: featuredVideos?.length > 2 ? 2 : featuredVideos?.length,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var clientSlider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      Previous
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      Next
    </button>
  );
  var testimonialSlider = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    slidesToShow: testimonials?.length >= 2 ? 2 : testimonials?.length,
    slidesToScroll: 1,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: testimonials?.length >= 2 ? 2 : testimonials?.length,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData?.head_title ? seoData.head_title : ""}</title>
        <meta
          name="title"
          content={seoData?.meta_title ? seoData.meta_title : ""}
        ></meta>
        <meta
          name="description"
          content={seoData?.meta_description ? seoData.meta_description : ""}
        ></meta>
        <meta
          name="keyword"
          content={seoData?.meta_keyword ? seoData.meta_keyword : ""}
        ></meta>
        <link rel="canonical" href="https://oliverllp.com/" />
      </Helmet>
      {/* <Navbarweb /> */}
      {isLoading ? (
        <ShimmerEffect height={"28vh"} width={"100%"} />
      ) : (
        <header className="container-fluid p-0">
          <Slider {...header_settings}>
            {commonData?.header_banner && commonData?.header_banner?.length > 0
              ? commonData?.header_banner?.map((headerImg, index) => {
                  return (
                    <div
                      key={index + 1}
                      className="home-header-bg"
                      // style={{
                      //   backgroundImage: `url(${process.env.REACT_APP_API_URL}/${headerImg})`,
                      // }}
                    >
                      {headerImg ? (
                        <img
                          title={headerImg?.title ?? ""}
                          caption={headerImg?.caption ?? ""}
                          description={headerImg?.description ?? ""}
                          src={`${process.env.REACT_APP_API_URL}/${headerImg?.path}`}
                          alt={headerImg?.alternate_text ?? ""}
                        />
                      ) : (
                        ""
                      )}
                      {/* <div className="container h-100">
                            <div className="row justify-content-center h-100">
                              <div className="col-md-10 home-header-content">
                                <p
                                  className={`text-white heading-font-family ${
                                    playAnimation ? "header-text-ani" : ""
                                  }`}
                                >
                                  {commonData?.header_heading ?? ""}
                                </p>
                                <div>
                                  <a
                                    href={`${commonData?.header_link ?? "/"}`}
                                    target="_blank"
                                    className=""
                                  >
                                    GET IN TOUCH
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div> */}
                    </div>
                  );
                })
              : ""}
          </Slider>
        </header>
      )}
      
      <div>
        <HomeProductList/>
      </div>

      {/* ABOUT SECTION */}
      <section className="container pt-5">
        <div className="row ">
          <div className="col-md-12 text-center ">
            <p className="text-uppercase mb-2 ps-2 home-section-heading ">
              about us
            </p>
            <h1 className="font-24 font-sm-18 fw-500 dark-79 mb-3 heading-font-family">
              {isLoading ? (
                <ShimmerEffect width={"30vw"} height={"20px"} />
              ) : (
                commonData?.about_heading ?? ""
              )}
            </h1>
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 text-sm-justify ">
            <div className=" home-about-content">
              {isLoading ? (
                <ShimmerEffect height={"30vh"} width={"100%"} />
              ) : (
                <div
                  className="dark-font text-justify table-responsive sunEditor-react"
                  dangerouslySetInnerHTML={{
                    __html: commonData?.about_description ?? "",
                  }}
                ></div>
              )}
            </div>
          </div>
          <div className="col-md-5 col-lg-5 my-2">
            {isLoading ? (
              <ShimmerEffect height={"50vh"} width={"100%"} />
            ) : commonData?.about_image?._id ? (
              <img
                src={`${process.env.REACT_APP_API_URL}/${commonData?.about_image?.path}`}
                title={commonData?.about_image?.title ?? ""}
                caption={commonData?.about_image?.caption ?? ""}
                description={commonData?.about_image?.description ?? ""}
                alt="about-img"
                className="img-fluid rounded"
              />
            ) : (
              <img
                src="/images/historyImg.png"
                alt="about-img"
                className="img-fluid rounded"
              />
            )}
          </div>
        </div>
      </section>

      {/* GET IN TOUCH SECTION */}
      <section
        className="container-fluid get-in-touch-wrapper"
        // style={{
        //   backgroundImage: commonData?.section_banner
        //     ? `url(${process.env.REACT_APP_API_URL}/${commonData?.section_banner} )`
        //     : "#000",
        // }}
      >
        <div className="get-in-touch-bg">
          <img
            src={`${process.env.REACT_APP_API_URL}/${commonData?.section_banner?.path} `}
            alt={
              commonData?.section_banner?.alternate_text ?? "get-in-touch-img"
            }
            title={commonData?.section_banner?.title ?? ""}
            caption={commonData?.section_banner?.caption ?? ""}
            description={commonData?.section_banner?.description ?? ""}
          />
        </div>
        <div className="container py-5">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-7">
              {isLoading ? (
                <ShimmerEffect width={"40vw"} height={"30px"} />
              ) : (
                <p className="dark-font font-30 text-white">
                  {commonData?.section_heading}
                  <span className="fw-bold">
                    {commonData?.mobile ? "+91-" + commonData?.mobile : ""}
                  </span>
                </p>
              )}
            </div>
            <div className="col-md-3 text-end">
              <div className="getInTouch-btn">
                <a
                  href={`tel:${commonData?.mobile}`}
                  className="text-white"
                  // target="_blank"
                >
                  GET IN TOUCH
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* SERVICES SECTION */}
      {services && services?.length > 0 ? (
        <section className="container-fluid background-light py-3">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="row">
                  <div className="col-md-12 text-center ">
                    <p className="text-uppercase mb-2 home-section-heading">
                      Our Services & solutions
                    </p>
                  </div>
                </div>
                <div className="row py-5">
                  {isLoading
                    ? [...Array(4)].map((item, index) => {
                        return (
                          <div key={index + 1} className="col-md-3">
                            <div className="service-card  text-center">
                              <ShimmerEffect height={"160px"} width={"140px"} />

                              <ShimmerEffect
                                height={"20px"}
                                width={"200px"}
                                className="my-3"
                              />

                              <ShimmerEffect height={"20px"} width={"200px"} />
                            </div>
                          </div>
                        );
                      })
                    : services.map((service, index) => {
                        return (
                          <div key={index + 1} className="col-md-3">
                            <div className="service-card text-center">
                              {service?.icon?._id ? (
                                <img
                                  src={`${process.env.REACT_APP_API_URL}/${service?.icon?.path}`}
                                  alt={
                                    service?.icon?.alternate_text ??
                                    "service-icon"
                                  }
                                  title={service?.icon?.title ?? ""}
                                  caption={service?.icon?.caption ?? ""}
                                  description={service?.icon?.description ?? ""}
                                  className="img-fluid"
                                />
                              ) : (
                                ""
                              )}

                              <div className="mt-3">
                                <p className="font-18 fw-bold">
                                  {service?.heading}
                                </p>
                                <div
                                  className="dark-font font-14 fw-500 table-responsive sunEditor-react"
                                  dangerouslySetInnerHTML={{
                                    __html: service?.description ?? "",
                                  }}
                                >
                                  {/* {service?.description} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      {/* OUR PRODUCTS SECTION */}
      {product && product?.length > 0 ? (
        <section className="container-fluid py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="row justify-content-center">
                  <div className="col-md-12 text-center ">
                    <p className="text-uppercase mb-2 home-section-heading">
                      Our products
                    </p>
                  </div>

                  <div className="col-md-12 text-center py-4">
                    <Slider {...settings}>
                      {product.map((element, index) => {
                        return (
                          <div key={index + 1} className="px-2">
                            <div className="card home-product-card-slider custom-card-shadow border-0 my-2">
                              <div className="card-body text-center">
                                <p className="mb-2 font-18 fw-500 heading-dark">
                                  {element?.name}
                                </p>
                                <Link
                                  to={`/product-details/${element?.product_slug}`}
                                  state={{ id: element?._id }}
                                  className="fw-500 font-16"
                                >
                                  Know More
                                  <span>
                                    <BiRightArrowCircle className="fs-5 ms-2" />
                                  </span>
                                </Link>
                                <div className="mt-3 ">
                                  {element?.images &&
                                  element?.images?.length > 0 ? (
                                    <img
                                      src={`${process.env.REACT_APP_API_URL}/${element?.images[0]?.path}`}
                                      alt={`${
                                        element?.images[0]?.alternate_text ??
                                        "product-img"
                                      }`}
                                      title={`${
                                        element?.images[0]?.title ?? ""
                                      }`}
                                      caption={`${
                                        element?.images[0]?.caption ?? ""
                                      }`}
                                      description={`${
                                        element?.images[0]?.description ?? ""
                                      }`}
                                      className="img-fluid"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      {/* OUR CLIENTS SECTION */}
      {commonData?.partners && commonData?.partners?.length > 0 ? (
        <section className="container-fluid background-light py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="row">
                  <div className="col-md-12 text-center ">
                    <p className="text-uppercase mb-2 home-section-heading">
                      Our clients
                    </p>
                  </div>
                </div>
                <div className="row justify-content-center py-3">
                  <div className="col-md-12 text-center">
                    <Slider {...clientSlider}>
                      {commonData?.partners?.map((partner, index) => {
                        return (
                          <div key={index + 1} className="my-3">
                            {partner?._id ? (
                              <img
                                src={`${process.env.REACT_APP_API_URL}/${partner?.path}`}
                                alt={`${
                                  partner?.alternate_text ?? "client-img"
                                } `}
                                caption={partner?.caption ?? ""}
                                title={partner?.title ?? ""}
                                description={partner?.description ?? ""}
                                className="img-fluid m-auto"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      {featuredVideos && featuredVideos?.length > 0 ? (
        <section className="container py-4">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-12 text-center ">
                  <p className="text-uppercase mb-2 home-section-heading">
                    Featured Videos
                  </p>
                </div>
                <div className=" col-12 text-center my-4">
                  <Slider {...videoSettings}>
                    {featuredVideos.map((element, index) => {
                      return (
                        <div
                          key={index + 1}
                          className="position-relative featuredVideoWrapper text-center custom-card-shadow "
                        >
                          {element?.image?._id ? (
                            <img
                              src={`${process.env.REACT_APP_API_URL}/${element?.image?.path}`}
                              alt={`${
                                element?.image?.alternate_text ?? "video-img"
                              }`}
                              title={`${element?.image?.title ?? ""}`}
                              caption={`${element?.image?.caption ?? ""}`}
                              description={`${
                                element?.image?.description ?? ""
                              }`}
                              className="img-fluid m-auto"
                            />
                          ) : (
                            ""
                          )}

                          <a
                            href={`${element?.link}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <AiFillYoutube className="featuredVideoIcon text-danger" />
                          </a>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      {/* WHAT OUT CLIENT SAYS SECTION */}
      {testimonials && testimonials?.length > 0 ? (
        <section className="container-fluid background-light py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="row">
                  <div className="col-md-12 text-center ">
                    <p className="text-uppercase mb-2 home-section-heading">
                      What Our Clients Says
                    </p>
                  </div>
                </div>
                <div className="row justify-content-center  pb-3">
                  <div className="col-md-12 text-center test-slider">
                    <Slider {...testimonialSlider}>
                      {testimonials.map((testimonial, index) => {
                        return (
                          <div key={index + 1} className="px-2 my-3">
                            <div
                              key={index + 1}
                              className="light-custom-shadow   p-3"
                            >
                              <div className="row align-items-center justify-content-center">
                                <div className="col-4 text-center">
                                  {testimonial?.client_image?._id ? (
                                    <img
                                      src={`${process.env.REACT_APP_API_URL}/${testimonial?.client_image?.path}`}
                                      alt={`${
                                        testimonial?.client_image
                                          ?.alternate_text ?? "client-img"
                                      }`}
                                      title={`${
                                        testimonial?.client_image?.title ?? ""
                                      }`}
                                      caption={`${
                                        testimonial?.client_image?.caption ?? ""
                                      }`}
                                      description={`${
                                        testimonial?.client_image
                                          ?.description ?? ""
                                      }`}
                                      className="partner-logo-height m-auto"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="row mt-4">
                                <div className="col-md-12 text-center">
                                  <p className="fw-500 fs-5 font-secondary ">
                                    {testimonial?.client_name}
                                  </p>
                                  <p className="font-18 fw-500 text-secondary">
                                    <FaQuoteLeft /> {testimonial?.client_review}{" "}
                                    <FaQuoteRight />
                                  </p>
                                  <div>
                                    {[...Array(testimonial?.client_rating)].map(
                                      (e, i) => {
                                        return (
                                          <span key={i + 1}>
                                            <AiFillStar className="fs-6 text-warning" />
                                          </span>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      {/* FOOTER  */}

      {/* <Footerweb /> */}
    </div>
  );
}
